import React from "react";
import App from "next/app";
import {Flex} from "@chakra-ui/core";
import ThemeProvider from "@pency/components/structure/ThemeProvider";
import {appWithTranslation} from "next-i18next";

import ErrorScreen from "./_error";

import reporter from "~/reporting";

process.on("unhandledRejection", (error: Error) => {
  reporter.exception(error, {origin: "server | unhandledRejection"});
});

process.on("uncaughtException", (error: Error) => {
  reporter.exception(error, {origin: "server | uncaughtException"});
});

class Pency extends App {
  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    reporter.exception(error, {extras: errorInfo, origin: "client"});

    super.componentDidCatch(error, errorInfo);
  }

  render() {
    const {Component, pageProps} = this.props;
    const {statusCode: error} = pageProps;

    return (
      <ThemeProvider>
        {error ? (
          <ErrorScreen statusCode={error} />
        ) : (
          <Flex direction="column" height="100%">
            <Component {...pageProps} />
          </Flex>
        )}
      </ThemeProvider>
    );
  }
}

export default appWithTranslation(Pency);
